import type { FeedbackTypes } from './misc';

export enum KsRendererClientEvents {
  ready = 'ready',
}

/**
 * Message sent from the renderer client (in the iframe) to the App UI parent window
 * using `window.parent.postMessage()`.
 * Note that this likely will become a union type of all possible messages
 */
export type KsRendererClientMsg =
  | {
      /**
       * Sent when a `ResizeObserver` detects a change in the contents height or width.
       */
      type: 'ksRendererClient.contentResized';
      height: number;
      width: number;
    }
  | {
      type: 'ksRendererClient.showToast';
      msg: string;
      feedbackType?: FeedbackTypes;
    }
  /**
   * Tracks the scroll position of the iframe's content
   */
  | {
      type: 'ksRendererClient.scrollPosition';
      scrollTop: number;
      scrollLeft: number;
    }
  /**
   * Sent when the iframe content is fully loaded
   */
  | {
      type: 'ksRendererClient.ready';
    };

/**
 * Message sent from the App UI parent window to the renderer client (in the iframe)
 * using `window.parent.postMessage()`.
 */
export type KsRendererIncomingMsg = {
  type: 'ksRendererClient.updateScrollPosition';
  scrollTop: number;
  scrollLeft: number;
};

/**
 * The meta data is stored in a script tag with this id
 * @see {KsRendererClientMeta}
 */
export const ksRendererClientMetaId = 'ks-meta';

/**
 * Metadata about the current renderer client demo
 * @see {ksRendererClientMetaId} for the ID of the script tag
 */
export interface KsRendererClientMeta {
  patternId: string;
  templateId: string;
  demoId: string;
  assetSetId: string;
  isInIframe: boolean;
  websocketsPort: number;
}
