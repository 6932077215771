import { send, StateNodeConfig } from 'xstate';
import { assign } from '@xstate/immer';
import { getKsClaims } from '@knapsack/core';
import { hasuraGql } from '@/services/hasura-client';
import { PromiseResult } from '@/utils/type-utils';
import {
  auth0,
  loginWithPopupErrors,
  type Auth0IdToken,
  type Auth0User,
} from '@/services/auth0';
import { isBrowser } from '@knapsack/utils';
import { knapsackGlobal } from '@/global';
import { isCypress, type LogoutRedirectQueryParam } from '@/utils/constants';
import { uiMachine } from '../../ui/ui.xstate';
import {
  AppEvents,
  AppContext,
  AppStateSchema,
  createInvokablePromise,
  sendUserMessage,
  APP_SUB_MACHINE_IDS,
} from '../app.xstate-utils';
import { getUserRoleOverride } from '../../../../utils/user-override-utils';
import type { UiEvents } from '../../ui/types';
import type { AppClientDataEvents } from '../../app-client-data';

/**
 * Fetch user from db
 */
async function loadUser({ userId, email }: { userId: string; email: string }) {
  const today = new Date().toISOString();
  if (!userId) throw new Error(`Load User missing userId`);
  if (!email) throw new Error(`Load User missing email`);
  try {
    const res = await hasuraGql.UpdateAndGetUser({
      email,
      userId,
      today,
    });
    return res.user;
  } catch (e) {
    console.error(e);
    throw new Error(
      `Loading User Details failed. userId probably does not exist in our DB: "${userId}", or auth token is bad.`,
    );
  }
}

function normalizeUser({
  auth0User,
  idToken,
}: {
  auth0User: Auth0User;
  idToken: Auth0IdToken;
}): AppContext['user'] {
  const { email, email_verified: emailVerified } = auth0User;
  const { isSuperAdmin, siteRoleMap, userId, getSiteRole } =
    getKsClaims(idToken);
  const roleOverride = getUserRoleOverride();
  return {
    userId,
    email,
    emailVerified,
    isSuperAdmin,
    membershipSiteIds: Object.keys(siteRoleMap ?? {}).sort(),
    getSiteRole:
      isSuperAdmin && roleOverride ? () => roleOverride : getSiteRole,
  };
}

export const userStateConfig: StateNodeConfig<
  AppContext,
  AppStateSchema['states']['user'],
  AppEvents
> = {
  id: 'user',
  initial: 'unknown',
  strict: true,
  states: {
    unknown: {
      invoke: createInvokablePromise<{ user?: AppContext['user'] }>({
        id: 'authPromiseChecker',
        src: async () => {
          // Just in case
          if (!isBrowser) {
            // it's ok, we'll just stay in the unknown state
            // might be b/c of SSR or Unit Tests
            return {};
          }

          if (isCypress()) {
            const { getCypressSessionConfig } = await import(
              '@/domains/users/utils/cypress-session-config'
            );
            const {
              user: { email, userId, userType },
            } = await getCypressSessionConfig();
            return {
              user: {
                userId,
                email,
                emailVerified: true,
                isSuperAdmin: false,
                // having more than one so when we visit `/` we do not get redirected to only siteId in array
                membershipSiteIds: ['ks-sandbox', 'ks-test'],
                // note that this is only going to work for `ks-sandbox` site
                getSiteRole: (siteId) =>
                  siteId === 'ks-sandbox' ? userType : 'ANONYMOUS',
              },
            };
          }
          const url = new URL(window.location.href);
          if (url.hash.includes('access_token') && url.hash.includes('state')) {
            // When SSO signs in outside of KS
            try {
              await auth0.getTokenSilently();
              let attempts = 0;
              const giveUpAfterMs = 5_000;
              const intervalMs = 100;
              const maxAttempts = giveUpAfterMs / intervalMs;
              const id = setInterval(() => {
                attempts += 1;
                if (typeof knapsackGlobal.replaceUrl === 'function') {
                  url.hash = '';
                  knapsackGlobal.replaceUrl(url.toString());
                  clearInterval(id);
                }
                if (attempts > maxAttempts) clearInterval(id);
              }, intervalMs);
            } catch (e) {
              console.log('error getting token silently', e);
            }
          }
          // actually faster to run these two promises serially vs in parallel
          // because they cache results instead of promises sadly
          const auth0User = await auth0.getUser();
          if (!auth0User) return {};
          const idToken = await auth0.getIdTokenClaims();
          return { user: normalizeUser({ auth0User, idToken }) };
        },
        onDone: [
          {
            cond: (_, event) => !!event.data?.user,
            target: 'loggedIn',
            actions: assign((ctx, { data }) => {
              ctx.user = data.user;
            }),
          },
          { target: 'loggedOut' },
        ],
        onErrorTarget: 'loggedOut',
        onErrorActions: [
          assign((ctx, { data: error }) => {
            ctx.userAuthError = {
              title: 'User Error',
              description: error.message,
            };
          }),
          {
            type: 'sendUserMessage',
            exec(_, { data: error }) {
              console.error(error);
              sendUserMessage({
                type: 'error',
                title: 'User Error',
                message: error.message,
              });
            },
          },
        ],
      }),
    },
    loggedOut: {
      initial: 'idle',
      states: {
        idle: {
          exit: [
            assign((ctx) => {
              ctx.userAuthError = undefined;
            }),
          ],
          on: {
            'user.signIn': 'loggingIn',
          },
        },
        loggingIn: {
          entry: [
            assign((ctx) => {
              ctx.userAuthError = undefined;
            }),
          ],
          invoke: createInvokablePromise<{ user: AppContext['user'] }>({
            id: 'authUserLoggingIn',
            src: async (_, event) => {
              if (event.type !== 'user.signIn') {
                throw new Error(
                  `Expected user.signIn event, received: ${event.type}`,
                );
              }
              if (isCypress()) {
                throw new Error('Cypress login not supported');
              }
              const { connectionId } = event;
              try {
                await auth0.loginWithPopup(
                  {
                    authorizationParams: {
                      ...(connectionId ? { connection: connectionId } : {}),
                    },
                  },
                  {
                    timeoutInSeconds: 60,
                  },
                );
              } catch (error) {
                if (error instanceof loginWithPopupErrors.PopupCancelledError) {
                  throw new Error('Popup was closed before login completed');
                }
                if (error instanceof loginWithPopupErrors.PopupTimeoutError) {
                  throw new Error('Login popup timed out');
                }
                throw error;
              }
              // actually faster to run these two promises serially vs in parallel
              // because they cache results instead of promises sadly
              const auth0User = await auth0.getUser();
              if (!auth0User) {
                throw new Error('User not found after login');
              }
              const idToken = await auth0.getIdTokenClaims();
              return { user: normalizeUser({ auth0User, idToken }) };
            },
            // starts over and process our new user
            onDoneTarget: '#app.user.loggedIn',
            onDoneActions: [
              assign((ctx, { data }) => {
                ctx.user = data.user;
              }),
            ],
            onErrorTarget: 'idle',
            onErrorActions: [
              assign((ctx, { data: error }) => {
                ctx.userAuthError = {
                  title: 'Error logging in',
                  description: error.message,
                };
              }),
              {
                type: 'sendUserMessage',
                exec(_, { data: error }) {
                  console.error(error);
                  sendUserMessage({
                    type: 'error',
                    title: 'Error logging in',
                    message: error.message,
                    autoClose: 3_000,
                  });
                },
              },
            ],
          }),
        },
      },
    },
    loggedIn: {
      initial: 'loadingDetails',
      entry: [
        send(
          (): UiEvents => ({
            type: 'user.stateChanged',
            isLoggedIn: true,
          }),
          { to: uiMachine.id },
        ),
      ],
      exit: [
        assign((ctx) => {
          ctx.user = null;
        }),
        send(
          (): UiEvents => ({
            type: 'user.stateChanged',
            isLoggedIn: false,
          }),
          { to: uiMachine.id },
        ),
        send((): AppClientDataEvents => ({ type: 'user.signedOut' }), {
          to: APP_SUB_MACHINE_IDS.appClientData,
        }),
        {
          type: 'clearing Apollo cache',
          exec: () =>
            import('@/services/util-apollo-graphql.client').then(
              ({ resetApolloClientStore }) => resetApolloClientStore(),
            ),
        },
      ],
      on: {
        'user.signOut': '.loggingOut',
      },
      invoke: {
        id: 'authWatcher',
        src: () => (sendEvent) => {
          if (isCypress()) return;
          const check = () => {
            auth0.getTokenSilently().catch((e) => {
              console.warn(
                `Error getting token silently, signing out. ${e.message}`,
              );
              sendEvent({ type: 'user.signOut' });
            });
          };
          check();
          const intervalId = setInterval(check, 60_000);
          return () => clearInterval(intervalId);
        },
      },
      states: {
        loggingOut: {
          invoke: createInvokablePromise<void>({
            id: 'loggingOut',
            src: async () => {
              if (isCypress()) {
                throw new Error('Cypress logout not supported');
              }
              const u = new URL(window.location.origin);
              const paths = window.location.pathname.split('/');
              // getting the siteId from the pathname instead of context b/c we might be on a "You don't have access to this site" page with a logout button - we want them to end up back on that same page so they can try a different login
              const siteId = paths[1] === 'site' ? paths[2] : undefined;
              if (siteId) {
                // see `middleware.ts` for where this is used to ensure users end up on their workspace root instead of the default app home
                // Auth0 has restrictions on where users can be redirected after logout, but it's ok to use a query params to store the intended destination
                u.searchParams.set(
                  'ks-logout-redirect' satisfies LogoutRedirectQueryParam,
                  `/site/${siteId}/${paths[3]}`,
                );
              }
              await auth0.logout({
                logoutParams: {
                  returnTo: u.toString(),
                },
              });
            },
            onDoneTarget: '#app.user.loggedOut',
            onErrorTarget: '#app.user.loggedOut',
            onErrorActions: [
              {
                type: 'sendUserMessage',
                exec(_, { data: error }) {
                  console.error(error);
                  sendUserMessage({
                    type: 'error',
                    title: 'Error logging out',
                    message: error.message,
                  });
                },
              },
            ],
          }),
        },
        loadingDetails: {
          invoke: createInvokablePromise<PromiseResult<typeof loadUser>>({
            id: 'loadUserInfo',
            src: async (ctx) => {
              if (isCypress()) {
                return {
                  // having this set avoids the modal asking to set responsibility
                  responsibilityId: 'OTHER',
                  displayName: `Cypress ${ctx.user.getSiteRole('ks-sandbox')}`,
                };
              }
              return loadUser({
                userId: ctx.user?.userId,
                email: ctx.user?.email,
              });
            },
            onDoneTarget: 'loaded',
            onDoneAssignContext({ ctx, data }) {
              ctx.user.info = data;
            },
            onErrorTarget: 'loadingError',
            onErrorActions: [
              {
                type: 'sendUserMessage',
                exec(_, event) {
                  sendUserMessage({
                    type: 'error',
                    title: 'Error loading user info',
                    message: event.data.message,
                  });
                },
              },
            ],
          }),
        },
        loadingError: {},
        loaded: {},
      },
    },
  },
};
