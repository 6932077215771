'use client';

import type { KsAppClientDataNoMeta, Simplify } from '@knapsack/types';
import {
  send,
  EventObject,
  DoneInvokeEvent,
  Action,
  InvokeConfig,
  ActionMeta,
  ActionObject,
  State,
  Typestate,
  TransitionConfig,
  SingleOrArray,
  StateSchema,
  Interpreter,
} from 'xstate';
import { assign } from '@xstate/immer';
import { shallowEqual } from '@/utils/shallowEqual';
import deepEqual from 'deep-equal';
import type { Draft } from 'immer';
import {
  useDebugValue,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

/**
 * An object of functions that are called when an event/action is sent in.
 * The key is the event/action type, and the value is the function to call.
 * This function is an Immer draft function, so you can mutate the context.
 * That function has 2 params - define types for 2nd
 * The advantage of an ActionMap is that the definition of the action is
 * right next to the function that handles it.
 * @see {@link ExtractActionsFromActionMap} for how to get a union of all actions
 * @see {@link hasActionOnMap} for how to check if an action is in an ActionMap
 * @param Data The type of the data being manipulated - must be inside Immer produce function
 * @example
 * ```ts
 * const actionMap = {
 *  'page.updateTitle': (data, action: { pageId: string; title: string; }) => {
 *    data.pages[action.pageId].title = action.title;
 *  },
 *  'page.updateDescription': (data, action: { pageId: string; description: string; }) => {
 *    data.pages[action.pageId].description = action.description;
 *  },
 * } satisfies ActionMap;
 * ```
 */
export type ActionMap<
  /** The type of the data being manipulated - must be inside Immer produce function */
  Data extends Record<string, unknown> = KsAppClientDataNoMeta,
> = {
  [type: string]: (data: Draft<Data>, action: Record<string, unknown>) => void;
};

/**
 * Get a union of all actions from an {@link ActionMap}
 * @example
 * ```ts
 * const actionMap = {
 *  'page.updateTitle': (data, action: { pageId: string; title: string; }) => {
 *    data.pages[action.pageId].title = action.title;
 *  },
 *  'page.updateDescription': (data, action: { pageId: string; description: string; }) => {
 *    data.pages[action.pageId].description = action.description;
 *  },
 * } satisfies ActionMap;
 *
 * type Actions = ExtractActionsFromActionMap<typeof actionMap>;
 * // Actions would be equivalent to Actions2 below:
 * type Actions2 =
 *  | {
 *      type: 'page.updateTitle';
 *      pageId: string;
 *      title: string;
 *    }
 *  | {
 *      type: 'page.updateDescription';
 *      pageId: string;
 *      description: string;
 *    };
 * ```
 * @see {@link ActionMap}
 */
export type ExtractActionsFromActionMap<TheActionMap extends ActionMap> = {
  [Key in keyof TheActionMap]: Simplify<
    {
      type: Key;
    } & Parameters<TheActionMap[Key]>[1]
  >;
}[keyof TheActionMap];

/**
 * Check if an action/event is in an {@link ActionMap}
 * @see {@link ActionMap}
 * @see {@link getActionHandlerFromMap} for how to get the handler function
 * @see {@link ExtractActionsFromActionMap} for how to get a union of all actions
 * @example
 * ```ts
 * const actionMap = {
 *   'page.updateTitle': (data, action: { pageId: string; title: string; }) => {
 *     data.pages[action.pageId].title = action.title;
 *   },
 *   'page.updateDescription': (data, action: { pageId: string; description: string; }) => {
 *     data.pages[action.pageId].description = action.description;
 *   },
 * } satisfies ActionMap;
 *
 * const action: { type: string; pageId: string; title: string; } = {
 *   type: 'page.updateTitle',
 *   pageId: '123',
 *   title: 'My Page',
 * };
 *
 * if (hasActionOnMap(action, actionMap)) {
 *   getActionHandlerFromMap(action, actionMap)(data, action);
 * }
 * ```
 */
export function hasActionOnMap<
  TheActionMap extends ActionMap,
  TheAction extends { type: string },
  TheActions extends ExtractActionsFromActionMap<TheActionMap>,
>(
  /** The action/event - has `action.type` */
  action: TheAction,
  /** The {@link ActionMap} - we'll check to see if `action.type` is a key on this object */
  actionMap: TheActionMap,
): action is Extract<TheActions, TheAction> {
  return action.type in actionMap;
}

/**
 * Get the handler function for an action/event from an {@link ActionMap}
 * @see {@link ActionMap}
 * @see {@link hasActionOnMap} for how to check if an action is in an ActionMap
 * @see {@link ExtractActionsFromActionMap} for how to get a union of all actions
 */
export function getActionHandlerFromMap<
  TheActionMap extends ActionMap,
  TheAction extends { type: string },
  TheActionType extends Extract<
    ExtractActionsFromActionMap<TheActionMap>,
    TheAction
  >['type'],
>(
  /** The action/event - has `action.type` */
  action: TheAction,
  /** The {@link ActionMap} - we'll check to see if `action.type` is a key on this object */
  actionMap: TheActionMap,
): ActionMap[TheActionType] | null {
  if (!hasActionOnMap(action, actionMap)) return;
  return actionMap[action.type];
}

/**
 * For creating a React Redux like `useSelector()` hook for Xstate Context
 */
export interface TypedUseSelectorHook<MachineContext> {
  <TSelected>(
    selector: (context: MachineContext) => TSelected,
    opt?: {
      /** If `true` then deepEqual will be used to determine if React should re-render, otherwise shallowEqual is used */
      isEqualityDeep?: boolean;
      /** Like the 2nd param to `useEffect`, if these change then re-create the selector function. Any time you use a var in your selector function, it should also be place in here. */
      dependencies?: Array<
        string | boolean | number | ((...args: any[]) => any)
      >;
    },
  ): TSelected;
}

/**
 * Use Isomorphic Layout Effect
 * Borrowed from `react-redux` for use in creating our own `useSelector` https://github.com/reduxjs/react-redux/blob/a9235530f4/src/utils/useIsomorphicLayoutEffect.js
 * React currently throws a warning when using useLayoutEffect on the server.
 * To get around it, we can conditionally useEffect on the server (no-op) and
 * useLayoutEffect in the browser. We need useLayoutEffect to ensure the store
 * subscription callback always has the selector from the latest render commit
 * available, otherwise a store update may happen between render and the effect,
 * which may cause missed updates; we also must ensure the store subscription
 * is created synchronously, otherwise a store update may occur before the
 * subscription is created and an inconsistent state may be observed
 */
export const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'
    ? useLayoutEffect
    : useEffect;

export function getXstateUtils<
  MachineContext,
  MachineEvents extends EventObject,
  MachineTypestates extends Typestate<MachineContext>,
  MachineStateSchema extends StateSchema,
  // MachineEventsSent extends EventObject = AnyEventObject
>(): {
  createInvokablePromise: typeof createInvokablePromise;
  createAction: typeof createAction;
  createXstateHooks: typeof createXstateHooks;
} {
  type MyActionObject<Options> = ActionObject<MachineContext, MachineEvents> &
    Options;

  type SiteAction<Options> = {
    id: string;
    exec: (
      ctx: MachineContext,
      event: MachineEvents,
      meta: ActionMeta<MachineContext, MachineEvents> & {
        action: ActionObject<MachineContext, MachineEvents> & Options;
      },
    ) => void;
    create: (opt: Options) => {
      type: string;
    } & Options;
    isAction: (
      action:
        | MyActionObject<Options>
        | ActionObject<MachineContext, MachineEvents>,
    ) => action is MyActionObject<Options>;
  };

  function createAction<Options>({
    id,
    exec,
  }: {
    id: string;
    exec: (opt: {
      ctx: MachineContext;
      event: MachineEvents;
      action: MyActionObject<Options>;
      state: State<MachineContext, MachineEvents>;
    }) => void;
  }): SiteAction<Options> {
    const isAction: SiteAction<Options>['isAction'] = (
      action,
    ): action is MyActionObject<Options> => action.type === id;
    return {
      id,
      create: (opt) => ({ ...opt, type: id }),
      isAction,
      exec: (ctx, event, meta) => {
        if (!isAction(meta.action)) return;
        const { action, state } = meta;
        exec({ ctx, event, action, state });
      },
    };
  }

  function createInvokablePromise<Data, RejectError = Error>({
    id,
    src,
    onDone,
    onDoneTarget,
    onDoneActions = [],
    onErrorActions = [],
    onDoneSendActions,
    onErrorSendActions,
    onDoneAssignContext,
    onErrorAssignContext,
    onErrorTarget,
  }: {
    id?: string;
    src: (ctx: MachineContext, event: MachineEvents) => Promise<Data>;
    onDone?: SingleOrArray<
      TransitionConfig<MachineContext, DoneInvokeEvent<Data>>
    >;
    onDoneTarget?: string; // MachineTypestates['value']; // @todo figure out how to be top level (TopStateValues) or string
    onErrorTarget: string;
    onDoneAssignContext?: (opt: {
      ctx: Draft<MachineContext>;
      data: Data;
    }) => void;
    onErrorAssignContext?: (opt: {
      ctx: Draft<MachineContext>;
      error: RejectError;
    }) => void;
    onDoneActions?: Action<MachineContext, DoneInvokeEvent<Data>>[];
    onDoneSendActions?: Array<
      | MachineEvents
      | ((ctx: MachineContext, event: DoneInvokeEvent<Data>) => MachineEvents)
    >;
    onErrorActions?: Action<MachineContext, DoneInvokeEvent<RejectError>>[];
    onErrorSendActions?: Array<
      | MachineEvents
      | ((
          ctx: MachineContext,
          event: DoneInvokeEvent<RejectError>,
        ) => MachineEvents)
    >;
  }): InvokeConfig<MachineContext, MachineEvents> {
    if (onDone && onDoneTarget) {
      throw new Error(
        `Cannot use "createInvokablePromise" with "onDone" and "onDoneTarget". Pick one.`,
      );
    }
    if (onDoneAssignContext) {
      onDoneActions.push(
        assign<MachineContext, DoneInvokeEvent<Data>>((ctx, event) => {
          onDoneAssignContext({
            ctx,
            data: event.data,
          });
        }),
      );
    }

    if (onErrorAssignContext) {
      onErrorActions.push(
        assign<MachineContext, DoneInvokeEvent<RejectError>>((ctx, event) => {
          onErrorAssignContext({
            ctx,
            error: event.data,
          });
        }),
      );
    }

    if (onDoneSendActions) {
      onDoneSendActions.forEach((onDoneSendAction) => {
        onDoneActions.push(send(onDoneSendAction));
      });
    }

    if (onErrorSendActions) {
      onErrorSendActions.forEach((onErrorSendAction) => {
        onErrorActions.push(send(onErrorSendAction));
      });
    }

    const result: ReturnType<typeof createInvokablePromise> = {
      src,
      onDone: onDone ?? {
        target: onDoneTarget,
        actions: onDoneActions,
      },
      onError: {
        target: onErrorTarget,
        actions: onErrorActions,
      },
    };
    if (id) result.id = id;

    return result;
  }

  /** Use after it's running */
  function createXstateHooks(
    service: Interpreter<
      MachineContext,
      MachineStateSchema,
      MachineEvents,
      MachineTypestates
    >,
  ): {
    useStateMatches: typeof useStateMatches;
    useCtxSelector: typeof useCtxSelector;
  } {
    function useStateMatches<TSV extends MachineTypestates['value']>(
      stateToMatch: TSV,
    ): boolean {
      const latestResult = useRef(service.state.matches(stateToMatch));
      const [matches, setMatches] = useState(latestResult.current);

      useIsomorphicLayoutEffect(() => {
        const { unsubscribe } = service.subscribe((state) => {
          const nextResult = state.matches(stateToMatch);
          if (latestResult.current === nextResult) return;
          latestResult.current = nextResult;
          setMatches(nextResult);
        });
        return unsubscribe;
      }, [stateToMatch]);

      useDebugValue(`${stateToMatch} = ${matches}`);
      return matches;
    }

    /**
     * Like Redux's `useSelector()` hook, but for Xstate Context
     * @param {selector} A function that selects data out of AppContext
     * @see https://github.com/reduxjs/react-redux/blob/a9235530f4799dd4b2acb3cc65e9caf32efbc44b/src/hooks/useSelector.js
     * @example
     * const siteId = useAppCtxSelector((ctx) => ctx.site?.meta.siteId);
     */
    const useCtxSelector: TypedUseSelectorHook<MachineContext> = (
      selector,
      { isEqualityDeep = false, dependencies = [] } = {},
    ) => {
      const latestSelector = useRef(selector);
      const latestResult = useRef<ReturnType<typeof selector>>(
        selector(service.getSnapshot().context),
      );
      const [result, setResult] = useState(latestResult.current);

      // Handle if `selector` changes between renders.
      // See https://github.com/reduxjs/react-redux/blob/a9235530f4799dd4b2acb3cc65e9caf32efbc44b/src/hooks/useSelector.js
      // Here's one possible scenario that could happen:
      // const siteId = useAppCtxSelector((ctx) => ctx.site?.meta.siteId);
      // const roleId = useAppCtxSelector((ctx) => ctx.user?.getSiteRole(siteId));
      useIsomorphicLayoutEffect(() => {
        latestSelector.current = selector;
      });

      function handleNextResult(nextResult: ReturnType<typeof selector>) {
        if (nextResult === latestResult.current) return;
        const isSame = isEqualityDeep
          ? deepEqual(nextResult, latestResult.current)
          : shallowEqual(nextResult, latestResult.current);
        if (isSame) return;
        latestResult.current = nextResult;
        setResult(nextResult);
      }

      useIsomorphicLayoutEffect(() => {
        // if the selector has change
        // but the context has not we need to update the result
        const nextResult = latestSelector.current(
          service.getSnapshot().context,
        );
        handleNextResult(nextResult);
      }, dependencies);

      useIsomorphicLayoutEffect(() => {
        const { unsubscribe } = service.subscribe((state) => {
          const nextResult = latestSelector.current(state.context);
          handleNextResult(nextResult);
        });
        return unsubscribe;
      }, [isEqualityDeep]);

      useDebugValue(result);
      return result;
    };

    return {
      useStateMatches,
      useCtxSelector,
    };
  }

  return {
    createInvokablePromise,
    createAction,
    createXstateHooks,
  };
}

type Subpath<T, Key extends keyof T = keyof T> = T[Key] extends Record<
  'states',
  any
>
  ? `${Key & string}.${MachineStateSchemaPaths<
      T[Key]['states'], // need to skip `states` object
      Exclude<keyof T[Key]['states'], keyof any[]>
    >}`
  : // Path<T[Key]['states'], Exclude<keyof T[Key], keyof any[]>> :
  T[Key] extends Record<string, any>
  ? `${Key & string}.${MachineStateSchemaPaths<
      T[Key],
      Exclude<keyof T[Key], keyof any[]>
    >}`
  : never;

/**
 * Turns a whole xstate schema into `thing.substate` types great for `state.matches('thing.substate')`
 * Huge thanks to https://github.com/ghoullier/awesome-template-literal-types
 * https://dev.to/nroboto/comment/1a8ao
 */
export type MachineStateSchemaPaths<
  MachineStateSchema extends Record<string, any>,
  Key extends keyof MachineStateSchema = keyof MachineStateSchema,
> = Key extends string ? Key | Subpath<MachineStateSchema, Key> : never;

/**
 * The placeholder action is the minimum needed for an event for it to be registered. This is sometimes used for future potentially useful events, or if elsewhere there is something listening to those events (like the design tokens slice currently)
 */
export const placeholderAction = (): void => {};
