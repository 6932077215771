import type { TokenDataSummary } from '@knapsack/design-token-utils';
import type {
  Role,
  KsAppClientDataNoMeta,
  KsChange,
  KsRemoteChange,
  FeedbackTypes,
} from '@knapsack/types';
import type { Typestate, Interpreter } from 'xstate';
import { ReactNode } from 'react';
import type { MachineStateSchemaPaths } from '../../xstate.utils';
import type { AppContext, SharedEvents } from '../app/app.xstate-utils';
import {
  emptyAppClientData,
  type AppClientDataReducerEvents,
} from './reducers';
import { DataChangesBroadcastChannel } from './utils/data-changes-broadcast-channel';

export interface AppClientDataCtx {
  active: KsAppClientDataNoMeta;
  tokenData: TokenDataSummary;
  tokenStyles: string;
  lastCommittedDataChangeId: string;
  lastDataChangeId: string;
  errors: string[];
  saveStack: KsChange[];
  dataChangesBroadcastChannel?: DataChangesBroadcastChannel;
  saverError?: string;
  /** errors from loading App Client Data */
  loaderError?: string;
  user?: {
    userId: string;
    roleForSite: Role;
  };
  site?: AppContext['site'];
  branchesStatusMsg?: {
    msg: ReactNode;
    type: FeedbackTypes;
  };
}

export const initialCtx: AppClientDataCtx = {
  active: emptyAppClientData,
  errors: [],
  saveStack: [],
  lastCommittedDataChangeId: '',
  lastDataChangeId: '',
  tokenStyles: '',
  tokenData: {
    tokens: [],
    tokensById: {},
    groups: [],
    groupsById: {},
    globalConfig: {},
    collections: [],
    collectionsById: {},
  },
};

/* eslint-disable @typescript-eslint/ban-types */
export interface AppClientDataState {
  states: {
    empty: {};
    ready: {
      states: {
        data: {};
        saver: {
          states: {
            idle: {};
            pushing: {};
            // pulling: {};
            error: {};
          };
        };
      };
    };
  };
}
/* eslint-enable @typescript-eslint/ban-types */

export type AppClientDataEvents =
  | AppClientDataReducerEvents
  | SharedEvents
  | {
      type: 'appClientData.externallyChanged';
      subtype: 'fullData';
      appClientData: KsAppClientDataNoMeta;
    }
  | {
      type: 'appClientData.externallyChanged';
      subtype: 'changes';
      changes: KsRemoteChange[];
    }
  | { type: 'appClientData.saverError'; errorMsg: string }
  | { type: 'appClientData.saverRetry' }
  | { type: 'appClientData.saver.save' }
  | { type: 'appClientData.prepForInstanceSwitch' }
  | {
      type: `appClientData.set${'LastCommitted' | 'Last'}DataChangeId`;
      id: string;
    }
  | {
      type: 'user.haveBothUserAndSite';
      userId: string;
      roleForSite: Role;
      siteId: string;
    }
  | { type: 'user.signedOut' }
  | {
      type: 'dataDoctor.run';
    };

export interface AppClientDataTypestates extends Typestate<AppClientDataCtx> {
  context: AppClientDataCtx;
  value: MachineStateSchemaPaths<AppClientDataState['states']>;
}

export type AppClientDataInterpreter = Interpreter<
  AppClientDataCtx,
  AppClientDataState,
  AppClientDataEvents,
  AppClientDataTypestates
>;
