import type { KsChange } from '@knapsack/types';
import { hasuraGql } from '@/services/hasura-client';
import { now } from '@knapsack/utils';

export async function saveDataChanges({
  changes,
  userId,
  instanceId,
}: {
  changes: KsChange[];
  userId: string;
  instanceId: string;
}): Promise<void> {
  const results = await hasuraGql.SaveDataChange({
    instanceId,
    now: now(),
    dataChange: {
      userId,
      instanceId,
      patches: changes.flatMap(({ patches }) => patches),
      inversePatches: changes.flatMap(({ inversePatches }) => inversePatches),
    },
  });
  const dataChangeId = results?.dataChange?.id;
  if (!dataChangeId) {
    throw new Error(`Could not save data changes`);
  }
}
